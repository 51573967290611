export const countries = {
    BY: "Беларусь",
    RU: "Россия",
    UA: "Украина",
    AD: "Андорра",
    AE: "ОАЭ",
    AF: "Афганистан",
    AG: "Антигуа и Барбуда",
    AI: "Ангилья",
    AL: "Албания",
    AM: "Армения",
    AO: "Ангола",
    AQ: "Антарктика",
    AR: "Аргентина",
    AS: "Американское Самоа",
    AT: "Австрия",
    AU: "Австралия",
    AW: "Аруба",
    AX: "Аландские острова",
    AZ: "Азербайджан",
    BA: "Босния и Герцеговина",
    BB: "Барбадос",
    BD: "Бангладеш",
    BE: "Бельгия",
    BF: "Буркина-Фасо",
    BG: "Болгария",
    BH: "Бахрейн",
    BI: "Бурунди",
    BJ: "Бенин",
    BL: "Сен-Бартелеми (Карибы)",
    BM: "Бермуды",
    BN: "Бруней",
    BO: "Боливия",
    BQ: "Бонэйр, Синт-Эстатиус и Саба",
    BR: "Бразилия",
    BS: "Багамские Острова",
    BT: "Бутан",
    BV: "Остров Буве",
    BW: "Ботсвана",
    BZ: "Белиз",
    CA: "Канада",
    CC: "Кокосовые острова",
    CD: "ДР Конго",
    CF: "ЦАР",
    CG: "Республика Конго",
    CH: "Швейцария",
    CI: "Кот-д’Ивуар",
    CK: "Острова Кука",
    CL: "Чили",
    CM: "Камерун",
    CN: "Китай",
    CO: "Колумбия",
    CR: "Коста-Рика",
    CU: "Куба",
    CV: "Кабо-Верде",
    CW: "Кюрасао",
    CX: "Остров Рождества",
    CY: "Кипр",
    CZ: "Чехия",
    DE: "Германия",
    DJ: "Джибути",
    DK: "Дания",
    DM: "Доминика",
    DO: "Доминиканская Республика",
    DZ: "Алжир",
    EC: "Эквадор",
    EE: "Эстония",
    EG: "Египет",
    EH: "САДР",
    ER: "Эритрея",
    ES: "Испания",
    ET: "Эфиопия",
    EU: "Европейский союз",
    FI: "Финляндия",
    FJ: "Фиджи",
    FK: "Фолклендские острова",
    FM: "Микронезия",
    FO: "Фарерские острова",
    FR: "Франция",
    GA: "Габон",
    GB: "Великобритания",
    GD: "Гренада",
    GE: "Грузия",
    GF: "Гвиана",
    GG: "Гернси",
    GH: "Гана",
    GI: "Гибралтар",
    GL: "Гренландия",
    GM: "Гамбия",
    GN: "Гвинея",
    GP: "Гваделупа",
    GQ: "Экваториальная Гвинея",
    GR: "Греция",
    GS: "Южная Георгия и Южные Сандвичевы Острова",
    GT: "Гватемала",
    GU: "Гуам",
    GW: "Гвинея-Бисау",
    GY: "Гайана",
    HK: "Гонконг",
    HM: "Херд и Макдональд",
    HN: "Гондурас",
    HR: "Хорватия",
    HT: "Гаити",
    HU: "Венгрия",
    ID: "Индонезия",
    IE: "Ирландия",
    IL: "Израиль",
    IM: "Остров Мэн",
    IN: "Индия",
    IO: "Британская территория в Индийском океане",
    IQ: "Ирак",
    IR: "Иран",
    IS: "Исландия",
    IT: "Италия",
    JE: "Джерси",
    JM: "Ямайка",
    JO: "Иордания",
    KE: "Кения",
    KG: "Киргизия",
    KH: "Камбоджа",
    KI: "Кирибати",
    KM: "Коморы",
    KN: "Сент-Китс и Невис",
    KP: "КНДР",
    KR: "Республика Корея",
    KW: "Кувейт",
    KY: "Острова Кайман",
    KZ: "Казахстан",
    LA: "Лаос",
    LB: "Ливан",
    LC: "Сент-Люсия",
    LI: "Лихтенштейн",
    LK: "Шри-Ланка",
    LR: "Либерия",
    LS: "Лесото",
    LT: "Литва",
    LU: "Люксембург",
    LV: "Латвия",
    LY: "Ливия",
    MA: "Марокко",
    MC: "Монако",
    MD: "Молдавия",
    ME: "Черногория",
    MF: "Сен-Мартен",
    MG: "Мадагаскар",
    MH: "Маршалловы Острова",
    MK: "Северная Македония",
    ML: "Мали",
    MM: "Мьянма",
    MN: "Монголия",
    MO: "Макао",
    MP: "Северные Марианские Острова",
    MQ: "Мартиника",
    MR: "Мавритания",
    MS: "Монтсеррат",
    MT: "Мальта",
    MU: "Маврикий",
    MV: "Мальдивы",
    MW: "Малави",
    MX: "Мексика",
    MY: "Малайзия",
    MZ: "Мозамбик",
    NA: "Намибия",
    NC: "Новая Каледония",
    NE: "Нигер",
    NF: "Остров Норфолк",
    NG: "Нигерия",
    NI: "Никарагуа",
    NL: "Нидерланды",
    NO: "Норвегия",
    NP: "Непал",
    NR: "Науру",
    NU: "Ниуэ",
    NZ: "Новая Зеландия",
    OM: "Оман",
    PA: "Панама",
    PE: "Перу",
    PF: "Французская Полинезия",
    PG: "Папуа — Новая Гвинея",
    PH: "Филиппины",
    PK: "Пакистан",
    PL: "Польша",
    PM: "Сен-Пьер и Микелон",
    PN: "Острова Питкэрн",
    PR: "Пуэрто-Рико",
    PS: "Государство Палестина",
    PT: "Португалия",
    PW: "Палау",
    PY: "Парагвай",
    QA: "Катар",
    RE: "Реюньон",
    RO: "Румыния",
    RS: "Сербия",
    RW: "Руанда",
    SA: "Саудовская Аравия",
    SB: "Соломоновы Острова",
    SC: "Сейшельские Острова",
    SD: "Судан",
    SE: "Швеция",
    SG: "Сингапур",
    SH: "Острова Святой Елены, Вознесения и Тристан-да-Кунья",
    SI: "Словения",
    SJ: "Шпицберген и Ян-Майен",
    SK: "Словакия",
    SL: "Сьерра-Леоне",
    SM: "Сан-Марино",
    SN: "Сенегал",
    SO: "Сомали",
    SR: "Суринам",
    SS: "Южный Судан",
    ST: "Сан-Томе и Принсипи",
    SV: "Сальвадор",
    SX: "Синт-Мартен",
    SY: "Сирия",
    SZ: "Эсватини",
    TC: "Теркс и Кайкос",
    TD: "Чад",
    TF: "Французские Южные и Антарктические территории",
    TG: "Того",
    TH: "Таиланд",
    TJ: "Таджикистан",
    TK: "Токелау",
    TL: "Восточный Тимор",
    TM: "Туркмения",
    TN: "Тунис",
    TO: "Тонга",
    TR: "Турция",
    TT: "Тринидад и Тобаго",
    TV: "Тувалу",
    TW: "Китайская Республика (Тайвань)",
    TZ: "Танзания",
    UG: "Уганда",
    UM: "Внешние малые острова США",
    US: "США",
    UY: "Уругвай",
    UZ: "Узбекистан",
    VA: "Ватикан",
    VC: "Сент-Винсент и Гренадины",
    VE: "Венесуэла",
    VG: "Виргинские Острова (Великобритания)",
    VI: "Виргинские Острова (США)",
    VN: "Вьетнам",
    VU: "Вануату",
    WF: "Уоллис и Футуна",
    WS: "Самоа",
    YE: "Йемен",
    YT: "Майотта",
    ZA: "ЮАР",
    ZM: "Замбия",
    ZW: "Зимбабве",
  };